@charset 'UTF-8';
@import "/Users/alivevn/Website/vhost/toy-factory-wp/src/scss/utility/_media-queries.scss";
@import "/Users/alivevn/Website/vhost/toy-factory-wp/src/scss/utility/_mixin.scss";


.page404 {
	&__content{
		padding: 50px 0 100px;
		text-align: center;
		font-size: 16px;
		.btn_grad{
			display: inline-block;
			margin-top: 20px;
			color: #007293;
			@include HOVER {
			  &:hover{
			    opacity: 0.5;
			  }
			}
		}
	}
}